import { BaseProxy } from '@/proxies/BaseProxy'

export class UploadFileProxy extends BaseProxy {
  constructor ({ parameters = {} } = {}) {
    super({
      endpoint: `UploadFiles`,
      parameters,
    })
  }
}
